import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';


const getTodayDate = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
};
const getTodayDateEnd = () => {
    const today = new Date();
    today.setHours(23, 59, 0, 0);
    return today;
};

function normalizeToMidnight(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

function isDateWithinRange(startDate, endDate, targetDate) {
    // Normalize dates to ignore time
    const start = normalizeToMidnight(startDate);
    const end = normalizeToMidnight(endDate);
    const target = normalizeToMidnight(targetDate);

    // Check if the target date is within the range
    return target >= start && target <= end;
}


const columns = [
    { field: 'id', headerName: 'id', hide: true, flex: 0 },
    { field: 'kickoffNo', headerName: 'Kickoff', flex: 2 },
    { field: 'league', headerName: 'League', flex: 3 },
    { field: 'arena', headerName: 'Arena', flex: 2 },
    { field: 'homeTeam', headerName: 'Home', flex: 2 },
    { field: 'awayTeam', headerName: 'Away', flex: 2 },
    { field: 'matchbank', headerName: 'Matchbank', flex: 2 },
];



function MatchStatus() {
    const [loading, setLoading] = useState(false);
    const [matches, setMatches] = useState([]);

    const [startDate, setStartDate] = useState(getTodayDate());
    const [endDate, setEndDate] = useState(getTodayDateEnd());
    const [searchTerm, setSearchTerm] = useState('');

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    // Update the search term state
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch('api/match');
                let data = await response.json();
                data = data.map((m, id) => { return { ...m, id, kickoffNo: new Date(m.kickoff).toLocaleString("nb-NO") + " " } });
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                const kickoffSpan = new Date();
                kickoffSpan.setDate(kickoffSpan.getDate() + 8);
                let smcMatches = data.filter(m => m.league == "Superettan" || m.league == "Allsvenskan");
                smcMatches = smcMatches.filter(m =>  new Date(m.kickoff) > yesterday && new Date(m.kickoff) < kickoffSpan);
              
                if (smcMatches.length < 30) {
                    for(const smc of smcMatches){ 
                            const response = await fetch('api/matchbank?nr=' + smc["matchId"]);
                            smc["matchbank"] = (await response.text()).replace('"', '').replace('"', '');
                    }
                }


                setMatches(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    function filterMatch(matches) {

        return matches.filter(item => {
            const date = new Date(item.kickoff);

            const isWithinDateRange = startDate && endDate ? isDateWithinRange(startDate, endDate, date) : false;
            const matchesSearchTerm = searchTerm ? JSON.stringify(item).toLowerCase().includes(searchTerm.toLowerCase()) : false;
            return isWithinDateRange || matchesSearchTerm;
        });
    }

    const filterMatches = filterMatch(matches);
    const filterMatchesRevesed = filterMatches.reverse();
   

    return (
        <div  >
            {loading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                </div>
            )}
            <h1>Matches status </h1>
            <div>
                <br></br>
                Select date span to filter matches from (Logistikkskjema kontribusjon, Occasional, Warnermedia/SMC)
                <div>
                    <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        placeholderText="Select a date range"
                        dateFormat="yyyy/MM/dd"
                    />
                </div>
            </div>
            <div>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Wildcard search"
                />
            </div>
            <Paper  >
                <DataGrid
                    rows={filterMatchesRevesed}
                    columns={columns}
                    sx={{ fontSize: 16 }}
                    columnVisibilityModel={{
                        id: false,
                    }}
                />
            </Paper>
        </div>
    );
}

export default MatchStatus;