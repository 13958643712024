import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <nav>
      
      </nav>
<div className="container">
      <Outlet />
      </div>
    </>
  )
};

export default Layout;