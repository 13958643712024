import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css'; 
import MatchStatus from './components/MatchStatus';
import Layout from './Layout';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index path="/" element={<MatchStatus />} /> 

                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
